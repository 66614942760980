import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  explorerLink,
  fetchAssetImage,
  getAccountInfo,
  getAsset,
  getAssetImage,
  placeholderImage,
  randomArchirand,
  randomImage,
  shuffle,
  somethingFromSome,
} from "./functions";
import {
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// AssetSelector
import AssetSellector from "./components/AssetSelector";

// Noodle
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import { styled } from "@mui/material/styles";

import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

import { CubeTextureLoader, MeshBasicMaterial, CubeTexture } from "three";
import LogoCube from "./LogoCube";
import { ButtonGroup, Card, Col, Image, Row, Spinner } from "react-bootstrap";
import classNames from "classnames";
import * as relayBackend from "./build/relay/index.main.mjs";
import { getApplicationAddress } from "algosdk";
import * as diceBackend from "./build/dice/index.main.mjs";
import archirand from "./statics/archirand";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { borderRadius } from "@mui/system";
import MouseIcon from "@mui/icons-material/Mouse";
import Icon from "react-crypto-icons";
import * as backend from "./build/dice/index.main.mjs";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import { Stage, Sprite } from "@inlet/react-pixi";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const GRID_SIZE = 9;

const colors = [
  "#84DE02",
  "#E88E5A",
  "#DDE26A",
  "#C53151",
  "#FFDF46",
  "#B05C52",
  "#FF4466",
  "#828E84",
  "#FD5240",
  "#391285",
  "#FF85CF",
  "#FF4681",
  "#4BC7CF",
  "#FF6D3A",
  "#FF404C",
  "#A0E6FF",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  maxWidth: "500px",
  //height: "80vh",
  background: "black",
  //border: "1px solid #ccc",
  color: "white",
  //boxShadow: 24,
  p: 5,
  textAlign: "center",
  //paddingBottom: "5em",
  //borderRadius: "1em",
  overflow: "hidden",
};

function Dice(props) {
  const loader = new CubeTextureLoader();
  loader.setPath("/");
  const images = Array(6).keys();
  const textureCube = loader.load(images);
  const material = new MeshBasicMaterial({
    color: 0x000000,
    envMap: textureCube,
  });

  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 2 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

const {
  REACT_APP_NETWORK_PROVIDER,
  REACT_APP_PPC_SHUFFLE,
  REACT_APP_PPC_DICE_GO,
  REACT_APP_PPC_DICE_ROLL,
} = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "TestNet";

function App() {
  const { width, height } = useWindowSize();
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [_, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [goingToDice, setGoingToDice] = useState(false);
  const [display, setDisplay] = useState(null);
  const [showDiceModal, setShowDiceModal] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(null);
  const [side, setSide] = useState(0);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [speed, setSpeed] = useState(1000);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });
  const [selection, setSelection] = useState([]);
  const [counter, setCounter] = useState(-1);

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    if (!state.acc) return;
    const timeout = setTimeout(() => loadAssets(false), 1);
    return () => clearTimeout(timeout);
  }, [state.acc]);

  useEffect(() => {
    if (!state.acc) return;
    (async () => {
      const ctc = state.acc.contract(diceBackend, side);
      const next = somethingFromSome(
        (v) => reach.bigNumberToNumber(v),
        0
      )(await ctc.v.next());
      const price = somethingFromSome(
        (v) => reach.formatCurrency(v),
        0
      )(await ctc.v.price());
      const asset = await getAsset(next);
      setNext({ ...asset, price });
    })();
  }, [side]);

  useEffect(() => {
    let counter = 0;
    const interval = setInterval(() => setCounter(counter++), speed);
    return () => clearInterval(interval);
  }, [speed]);

  const handleClose = () => {
    setLoading(false);
    setNext(null);
    setSide(0);
    setCounter(counter + 1);
    setSpeed(1000);
  };

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    //console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      //console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      //console.log(accInfo);
      const assets = {};
      const images = {};
      for (let i in accInfo.assets) {
        const asset = accInfo.assets[i];
        const assetId = asset["asset-id"];
        let asa = await getAsset(assetId).catch(console.dir);
        let image = await fetchAssetImage(asa);
        if (image.match(/#arc3/)) continue;
        //console.log({ asa, image });
        assets[assetId] = asa;
        //console.log({ asa });
        images[assetId] = image;
      }
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        assets,
        images,
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const loadAssets = async (isShuffle = true) => {
    //console.log("ROLLING");
    const dices = shuffle(await appService.getDices());
    const sides = [];
    const bounty = [];
    for (let i in dices) {
      if (sides.length + bounty.length >= GRID_SIZE) {
        break;
      }
      const { appId } = dices[i];
      const escrow = getApplicationAddress(appId);

      let account;
      try {
        const res = await getAccountInfo(escrow);
        account = res.account;
      } catch (e) {
        appService.removeDice(appId);
        continue;
      }
      const { assets, amount } = account;
      if (!assets) {
        continue;
      }

      const totalAmount = assets.reduce((acc, val) => acc + val.amount, 0);
      console.log({ appId, totalAmount });

      // add dices
      if (/*!isShuffle &&*/ amount > 1000000 && totalAmount === 0) {
        bounty.push({ assetId: 0, appId, type: "dice" });
        continue;
      }

      /*
      const ctc = state.acc.contract(backend, appId);
      const next = somethingFromSome(
        (v) => reach.bigNumberToNumber(v),
        0
      )(await ctc.v.next());
      //console.log({ assets, amount, totalAmount, next });
      */

      // add sides
      for (let j in assets) {
        const asset = assets[j];
        const amount = asset["amount"];
        const assetId = asset["asset-id"];
        if (amount === 0) {
          //console.log({ appId });
          continue;
        }
        /*
        if (assetId !== next) {
          continue;
        }
        */
        /*
        if (isShuffle) {
          sides.push({
            j,
            assetId,
            appId,
            type: "side",
            next: assetId === next,
          });
        } else {
          */
        //if(assetId !== next) {
        sides.push({
          j,
          assetId,
          appId,
          type: "side",
          //next: assetId === next,
        });
        //}
        /*
        }
        */
      }
    }
    const finalSides = shuffle([...sides, ...bounty]).slice(0); //, GRID_SIZE);
    /*
    if (finalSides.length === GRID_SIZE && bounty.length > 0) {
      finalSides.splice(Math.floor(Math.random() * GRID_SIZE), 1, bounty[0]);
    }
    */
    setState({ ...state, sides: finalSides });
    setLoading(false);
    setNext(null);
    setSide(0);
    setCounter(counter + 1);
  };

  const handleDisconnect = () => setState(initialState);

  const handleDiceCreate = async () => {
    if (selection.length < 6) {
      alert("select 6 assets");
      return;
    }
    // TODO add validation
    setLoading(true);
    //const { info } = await appService.createDice();
    const diceId = [1, ...selection].join("-");
    navigate(`/dice/${diceId}`);
  };

  const handleShuffle = async () => {};

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return state.acc ? (
    <>
      <Canvas>
        <Dice />
      </Canvas>
      {state.acc && side > 0 && next && (
        <Confetti style={{ zIndex: 9999 }} width={width} height={height} />
      )}
      <Container className="p-0">
        {false && counter > 0 && state.sides && state.sides.length > 0 && (
          <Typography variant="h1" className="text-light">
            {counter}
          </Typography>
        )}
        {/* grid */}
        {
          <Box sx={{ flexGrow: 1 }} className="pt-0 pb-0">
            {state.sides ? (
              state.sides.length > 0 ? (
                <>
                  <Grid
                    container
                    spacing={0}
                    rowSpacing={0}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Grid item>
                      {state.sides
                        .slice(
                          counter % state.sides.length,
                          (counter % state.sides.length) + 1
                        )
                        .map((el) => (
                          <div
                            style={{
                              color: "white",
                              width: "100%",
                              fontFamily: '"Press Start 2P", cursive',
                            }}
                          >
                            {(~el.assetId).toString(2).slice(1)}
                          </div>
                        ))}
                    </Grid>
                    {false &&
                      state.sides.slice(0, 4).map((el, i) => (
                        <Grid
                          key={`${i}`}
                          style={{
                            aspectRatio: "1",
                            overflow: "hidden",
                            //background: "white",
                            color: `rgb(${~(el.assetId & 255) & 255},${
                              ~(el.assetId & 255) & 255
                            },${~(el.assetId & 127) & 255}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            /*
                        backgroundImage: `url(/assets/0${
                          Number(el.j) + 1
                        }.png)`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        */
                          }}
                          item
                          xs={6}
                          onClick={
                            state.acc && el.type === "side"
                              ? () => {
                                  setDisplay(
                                    state.sides.filter(
                                      (side) => side.appId === el.appId
                                    )
                                  );
                                  setShowDiceModal(true);
                                }
                              : () => {}
                            /*
                        state.acc
                          ? () => {
                              const ctc = state.acc.contract(
                                diceBackend,
                                parseInt(el.appId)
                              );
                              ctc.a
                                .ppc(reach.parseCurrency(1))
                                .then(() => navigate(`/${el.appId}`));
                            }
                          : () => {}
                          */
                          }
                        >
                          {el.assetId == 0 ? (
                            <span style={{ fontSize: "8vw" }}>🧱</span>
                          ) : (
                            <Image
                              fluid
                              src={`/assets/${randomArchirand(el.assetId)}.png`}
                              /*
                          src={`/assets/${
                            el.next
                              ? randomArchirand(el.assetId)
                              : "0" + (Number(el.j) + 1)
                          }.png`}
                          */
                              style={{
                                objectFit: "cover",
                                objectPosition: "center",
                                width: "200%",
                                //opacity: "0.1",
                              }}
                            />
                          )}
                        </Grid>
                      ))}
                  </Grid>
                  {false && (
                    <Box sx={{ flexGrow: 1 }} className="p-3 pb-5">
                      <Typography variant="h6" className="text-dark">
                        <MouseIcon /> Click on image to view dice
                      </Typography>
                      <Typography variant="h6" className="text-dark">
                        <MonetizationOnIcon /> Cost
                      </Typography>
                      <Grid container className="p-2">
                        <Grid item xs={6} sm={3}>
                          <Typography variant="p" className="text-dark">
                            Shuffle&nbsp;
                            <Icon name="algo" size={12} />
                            &nbsp;{REACT_APP_PPC_SHUFFLE}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography variant="p" className="text-dark">
                            Go to dice&nbsp;
                            <Icon name="algo" size={12} />
                            &nbsp;{REACT_APP_PPC_DICE_GO}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography variant="p" className="text-dark">
                            Roll dice&nbsp;
                            <Icon name="algo" size={12} />
                            &nbsp;{REACT_APP_PPC_DICE_ROLL}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography variant="p" className="text-dark">
                            Buy&nbsp;
                            <Icon name="algo" size={12} />
                            &nbsp;<em>floor price</em>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="h6" className="text-dark">
                        <ShoppingCartIcon /> How to buy
                      </Typography>
                      <Grid container className="p-2">
                        <Grid item xs={6} sm={6}>
                          <Typography variant="p" className="text-dark">
                            Shuffle ({REACT_APP_PPC_SHUFFLE} ALGO) <br />
                            {`　`}→ Buy (floor price ALGO)
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Typography variant="p" className="text-dark">
                            Go to dice ({REACT_APP_PPC_DICE_GO} ALGO) <br />
                            {`　`}→ Roll dice (n x {REACT_APP_PPC_DICE_ROLL}{" "}
                            ALGO) <br />
                            {`　　`}→ Buy (floor price ALGO)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </>
              ) : (
                ""
              )
            ) : (
              <div
                style={{
                  textAlign: "center",
                  height: "60vh",
                  verticalAlign: "middle",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  color: "black",
                  fontSize: "30px",
                }}
              >
                <CircularProgress color="inherit" size={100} />
              </div>
            )}
          </Box>
        }
        {/* model */}
        {state.acc && display && (
          <Modal
            open={showDiceModal}
            onClose={() => setShowDiceModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style}>
              <Grid container>
                {display.map((el, i) => (
                  <Grid
                    item
                    xs={2}
                    style={
                      {
                        //background: 'url(/assets/0.png)',
                        //backgroundSize: 'cover'
                      }
                    }
                  >
                    {el.next ? (
                      <Image
                        style={{
                          opacity: "0.1",
                        }}
                        fluid
                        src={`/assets/${randomArchirand(el.assetId)}.png`}
                      />
                    ) : (
                      <Image fluid src={`/assets/0${i}.png`} />
                    )}
                  </Grid>
                ))}
              </Grid>
              <ButtonGroup size="lg" className="w-75 mt-5">
                <Button
                  variant="success"
                  disabled={loadingClaim}
                  onClick={() => {
                    setGoingToDice(true);
                    const ctc = state.acc.contract(
                      diceBackend,
                      display[0].appId
                    );
                    ctc.a
                      .ppc(reach.parseCurrency(REACT_APP_PPC_DICE_GO))
                      .then(() => navigate(`/${display[0].appId}`))
                      .catch(() => setGoingToDice(false));
                  }}
                >
                  {!goingToDice ? (
                    <div
                      style={{
                        fill: "white",
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "center",
                      }}
                    >
                      Go to dice&nbsp;
                      <Icon name="algo" size={13} />
                      &nbsp;1
                    </div>
                  ) : (
                    "Processing..."
                  )}
                </Button>
              </ButtonGroup>
            </Box>
          </Modal>
        )}
        {state.acc && side > 0 && next && (
          <>
            <Modal
              open={true}
              //onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box style={style}>
                {false && (
                  <Image
                    fluid
                    src={`/assets/${randomArchirand(next.asset.index)}.png`}
                  />
                )}
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="mt-3"
                >
                  Almost there!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {next.asset.params.name} (1 of {next.asset.params.total}) /{" "}
                  {next.asset.index} <br />
                  <a
                    href={explorerLink(next.asset.index)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Algoexplorer
                  </a>
                </Typography>
                <ButtonGroup size="lg" className="w-75 mt-5">
                  <Button
                    variant="light"
                    onClick={handleClose}
                    style={{
                      fontFamily: '"Press Start 2P", cursive',
                    }}
                  >
                    Reshuffle
                  </Button>
                  <Button
                    style={{
                      fontSize: "medium",
                      fontFamily: '"Press Start 2P", cursive',
                    }}
                    disabled={loadingClaim}
                    onClick={() => {
                      console.log("CLAIM");
                      setLoadingClaim(true);
                      state.acc
                        .tokenAccept(reach.bigNumberify(next.asset.index))
                        .then(() =>
                          state.acc.contract(diceBackend, side).a.touch()
                        )
                        .then(() => loadAssets())
                        .finally(() => {
                          setLoadingClaim(false);
                          setSpeed(1000);
                        });
                    }}
                  >
                    {loadingClaim ? (
                      <>Processing...</>
                    ) : (
                      <div
                        style={{
                          fill: "white",
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "center",
                        }}
                      >
                        Buy&nbsp;
                        {next.price}&nbsp; ALGO
                      </div>
                    )}
                  </Button>
                </ButtonGroup>
              </Box>
            </Modal>
            {false && (
              <Item className="mx-5 mt-3">
                {next.asset.params.name} (1 of {next.asset.params.total}) /{" "}
                {next.asset.index} <br />
                <a
                  href={explorerLink(next.asset.index)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Algoexplorer
                </a>
                <hr />
                <button
                  onClick={() => {
                    state.acc
                      .tokenAccept(reach.bigNumberify(next.asset.index))
                      .then(() =>
                        state.acc.contract(diceBackend, side).a.touch()
                      )
                      .then(() => loadAssets())
                      .finally(() => setSpeed(1000));
                  }}
                >
                  Claim
                </button>
              </Item>
            )}
          </>
        )}
      </Container>
      {/* shuffle button */}
      {state.acc && state.sides && state.sides.length > 0 && side === 0 && (
        <Box sx={{ m: 5 }}>
          <Button
            disabled={loading}
            variant="light"
            style={{
              position: "fixed",
              bottom: "0px",
              left: "0px",
              borderRadius: "0px",
            }}
            onClick={() => {
              setLoading(true);
              const i = Math.floor(Math.random() * state.sides.length);
              const { appId, type } = state.sides[i];
              const ctc = state.acc.contract(diceBackend, appId);
              if (type === "dice") {
                console.log("SMASH");
                ctc.p.Bob({
                  signal: () => {
                    appService
                      .removeDice(appId)
                      .then(() => loadAssets())
                      .finally(() => setLoading(false));
                  },
                });
              } else {
                // side
                console.log("SHUFFLE");
                new Promise((resolve) =>
                  setTimeout(() => {
                    setSpeed(90);
                    resolve();
                  }, 1000)
                )
                  .then(() =>
                    ctc.a.ppc(reach.parseCurrency(REACT_APP_PPC_SHUFFLE))
                  )
                  /*
                  .then(() => ctc.a.touch())
                  .then(() =>
                    ctc.p.Bob({
                      signal: () => {
                        appService
                          .removeDice(appId)
                          .then(() => loadAssets())
                          .finally(() => setLoading(false));
                      },
                    })
                  )
                  */
                  .then(() => setSpeed(100))
                  .then(() => setSide(appId))
                  .finally(() => {
                    setLoading(false);
                  });
              }
            }}
            className="w-100 text-light text-weight-bold"
          >
            {!loading ? (
              <div
                style={{
                  fill: "black",
                  color: "black",
                  fontFamily: '"Press Start 2P", cursive',
                }}
              >
                <span>Shuffle</span>&nbsp;
                {REACT_APP_PPC_SHUFFLE}&nbsp; ALGO
              </div>
            ) : (
              "Shuffling..."
            )}
          </Button>
        </Box>
      )}
    </>
  ) : addr ? (
    <div
      style={{
        textAlign: "center",
        height: "60vh",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        color: "black",
        fontSize: "30px",
      }}
    >
      <CircularProgress color="inherit" size={100} />
    </div>
  ) : (
    <Box
      style={{
        display: "flex",
        height: "90vh",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: '"Press Start 2P", cursive',
        color: "white",
      }}
    >
      Connect wallet to begin
    </Box>
  );
}

export default App;
