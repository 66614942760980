const settings = {
  provider: "MainNet",
  assets: {
    544176827: 3,
    544176834: 3,
    544176851: 3,
    544176889: 3,
    544176913: 3,
    544176936: 3,
    544176962: 3,
    544177010: 3,
    544177043: 3,
    544177055: 3,
    544177069: 3,
    544177094: 3,
    544177135: 3,
    544177149: 3,
    544177158: 3,
    544177175: 3,
    544177219: 3,
    544177260: 3,
    544177273: 3,
    544177289: 3,
    544177328: 3,
    544177363: 3,
    544177382: 3,
    544177392: 3,
    544177421: 3,
    544177465: 3,
    544177484: 3,
    544177507: 3,
    544177535: 3,
    544177571: 3,
    544177621: 3,
    544177643: 3,
    544177650: 3,
    544177731: 3,
    544177749: 3,
    544177773: 3,
    544177807: 3,
    544177825: 3,
    544177846: 3,
    544177866: 3,
    544177892: 3,
    544177932: 3,
    544177942: 3,
    544177960: 3,
    544177987: 3,
    544178028: 3,
    544178065: 3,
    544178070: 3,
    544178089: 3,
    544178123: 3,
    544178151: 3,
    544178170: 3,
    544178193: 3,
    544178222: 3,
    544178260: 3,
    544178282: 3,
    544178303: 3,
    544178336: 3,
    544178383: 3,
    544178404: 3,
    544178414: 3,
    544178433: 3,
    544178473: 3,
    544178602: 3,
    544178644: 3,
    544178666: 3,
    544178675: 3,
    544178696: 3,
    544178728: 3,
    544178751: 3,
    544178766: 3,
    544178788: 3,
    544178829: 3,
    544178851: 3,
    544178868: 3,
    544178876: 3,
    544178898: 3,
    544178945: 3,
    544178955: 3,
    544178969: 3,
    544178987: 3,
    544179032: 3,
    544179054: 3,
    544179071: 3,
    544179085: 3,
    544179122: 3,
    544179151: 3,
    544179167: 3,
    544179176: 3,
    544179199: 3,
    544179246: 3,
    544179262: 3,
    544179282: 3,
    544179379: 3,
    544179391: 3,
    544179426: 3,
    544179459: 3,
    544179472: 3,
    544179484: 3,
    544179523: 3,
    544179553: 2,
    544179589: 2,
    544179597: 2,
    544179605: 2,
    544179638: 2,
    544179672: 2,
    544179687: 2,
    544179704: 2,
    544179734: 2,
    544179767: 2,
    544179790: 2,
    544179815: 2,
    544179831: 2,
    544179869: 2,
    544179897: 2,
    544179919: 2,
    544179933: 2,
    544179958: 2,
    544179985: 2,
    544179999: 2,
    544180011: 2,
    544180028: 2,
    544180060: 2,
    544180157: 2,
    544180192: 2,
    544180206: 2,
    544180240: 2,
    544180273: 2,
    544180293: 2,
    544180318: 2,
    544180339: 2,
    544180362: 2,
    544180402: 2,
    544180419: 2,
    544180434: 2,
    544180463: 2,
    544180502: 2,
    544180544: 2,
    544180562: 2,
    544180584: 2,
    544180640: 2,
    544180701: 2,
    544180714: 2,
    544180738: 2,
    544180780: 2,
    544180832: 2,
    544180853: 2,
    544180881: 2,
    544180921: 2,
    544180960: 2,
    544180992: 2,
    544181017: 2,
    544181027: 2,
    544181160: 2,
    544181175: 2,
    544181215: 2,
    544181252: 2,
    544181277: 2,
    544181297: 2,
    544181347: 2,
    544181392: 2,
    544181416: 2,
    544181435: 2,
    544181457: 2,
    544181501: 2,
    544181530: 2,
    544181558: 2,
    544181594: 2,
    544181643: 2,
    544181696: 2,
    544181720: 2,
    544181754: 2,
    544181790: 2,
    544181843: 2,
    544181889: 2,
    544181912: 2,
    544181936: 2,
    544181975: 2,
    544182009: 2,
    544182024: 2,
    544182042: 2,
    544182070: 2,
    544182119: 2,
    544182191: 2,
    544182202: 2,
    544182218: 2,
    544182239: 2,
    544182276: 2,
    544182304: 2,
    544182320: 2,
    544182347: 2,
    544182375: 2,
    544182403: 2,
    544182425: 2,
    544182435: 2,
    544182480: 2,
    544182520: 2,
    544182547: 2,
    544182571: 2,
    544182596: 2,
    544182630: 2,
    544182663: 2,
    544182679: 2,
    544182698: 2,
    544182726: 2,
    544182759: 2,
    544182777: 2,
    544182789: 2,
    544182817: 2,
    544182841: 2,
    544182868: 2,
    544182883: 2,
    544182898: 2,
    544182978: 2,
    544183005: 2,
    544183047: 2,
    544183071: 2,
    544183082: 2,
    544183111: 2,
    544183153: 2,
    544183187: 2,
    544183197: 2,
    544183205: 2,
    544183224: 2,
    544183276: 2,
    544183302: 2,
    544183310: 2,
    544183325: 2,
    544183356: 2,
    544183385: 2,
    544183397: 2,
    544183413: 2,
    544183438: 2,
    544183470: 2,
    544183488: 2,
    544183498: 2,
    544183520: 2,
    544183570: 2,
    544183592: 2,
    544183605: 2,
    544183623: 2,
    544183665: 2,
    544183704: 2,
    544183798: 2,
    544183815: 2,
    544183833: 2,
    544183885: 2,
    544183899: 2,
    544183911: 2,
    544183941: 2,
    544183984: 2,
    544184031: 2,
    544184054: 2,
    544184076: 2,
    544184091: 2,
    544184135: 2,
    544184186: 2,
    544184207: 2,
    544184221: 2,
    544184261: 2,
    544184297: 2,
    544184322: 2,
    544184351: 2,
    544184375: 2,
    544184425: 2,
    544184468: 2,
    544184492: 2,
    544184513: 2,
    544184551: 2,
    544184590: 2,
    544184613: 2,
    544184643: 2,
    544184668: 2,
    544184781: 2,
    544184806: 2,
    544184843: 2,
    544184873: 2,
    544184883: 2,
    544184908: 2,
    544184952: 2,
    544184974: 2,
    544184993: 2,
    544185018: 2,
    544185054: 2,
    544185090: 2,
    544185116: 2,
    544185127: 2,
    544185179: 2,
    544185221: 2,
    544185241: 2,
    544185258: 2,
    544185295: 2,
    544185332: 2,
    544185353: 2,
    544185363: 2,
    544185378: 2,
    544185414: 2,
    544185440: 2,
    544185459: 2,
    544185471: 2,
    544185492: 2,
    544185529: 2,
    544185555: 2,
    544185640: 2,
    544185663: 2,
    544185692: 2,
    544185738: 2,
    544185743: 2,
    544185761: 2,
    544185787: 2,
    544185822: 2,
    544185830: 2,
    544185854: 2,
    544185873: 2,
    544185913: 2,
    544185929: 2,
    544185936: 2,
    544185950: 2,
    544185994: 2,
    544186010: 2,
    544186024: 2,
    544186037: 2,
    544186063: 2,
    544186094: 2,
    544186111: 2,
    544186122: 2,
    544186140: 2,
    544186173: 2,
    544186199: 2,
    544186212: 2,
    544186221: 2,
    544186245: 2,
    544186276: 2,
    544186342: 2,
    544186366: 2,
    544186389: 2,
    544186416: 2,
    544186426: 2,
    544186460: 2,
    544186490: 2,
    544186500: 2,
    544186508: 2,
    544186534: 2,
    544186567: 2,
    544186602: 2,
    544186621: 2,
    544186645: 2,
    544186677: 2,
    544186705: 2,
    544186712: 2,
    544186727: 2,
    544186767: 2,
    544186790: 2,
    544186809: 2,
    544186817: 2,
    544186842: 2,
    544186885: 2,
    544186905: 2,
    544186916: 2,
    544186929: 2,
    544186964: 2,
    544186991: 2,
    544187021: 2,
    544187123: 2,
    544187135: 2,
    544187190: 2,
    544187218: 2,
    544187238: 2,
    544187259: 2,
    544187288: 2,
    544187333: 2,
    544187366: 2,
    544187398: 2,
    544187423: 2,
    544187470: 2,
    544187504: 2,
    544187527: 2,
    544187567: 2,
    544187621: 2,
    544187673: 2,
    544187701: 2,
    544187726: 2,
    544187753: 2,
    544187789: 2,
    544187814: 2,
    544187830: 2,
    544187855: 2,
    544187900: 2,
    544187944: 2,
    544187977: 2,
    544188000: 2,
    544188028: 2,
    544188072: 2,
    544188186: 2,
    544188224: 2,
    544188243: 2,
    544188271: 2,
    544188305: 2,
    544188355: 2,
    544188393: 2,
    544188407: 1,
    544188429: 1,
    544188478: 1,
    544188508: 1,
    544188535: 1,
    544188563: 1,
    544188598: 1,
    544188637: 1,
    544188657: 1,
    544188670: 1,
    544188694: 1,
    544188726: 1,
    544188742: 1,
    544188756: 1,
    544188766: 1,
    544188796: 1,
    544188822: 1,
    544188834: 1,
    544188849: 1,
    544188864: 1,
    544188906: 1,
    544188927: 1,
    544188935: 1,
    544189052: 1,
    544189058: 1,
    544189117: 1,
    544189137: 1,
    544189149: 1,
    544189167: 1,
    544189203: 1,
    544189231: 1,
    544189258: 1,
    544189276: 1,
    544189300: 1,
    544189328: 1,
    544189349: 1,
    544189361: 1,
    544189388: 1,
    544189433: 1,
    544189442: 1,
    544189458: 1,
    544189468: 1,
    544189503: 1,
    544189527: 1,
    544189542: 1,
    544189561: 1,
    544189576: 1,
    544189605: 1,
    544189629: 1,
    544189644: 1,
    544189652: 1,
    544189686: 1,
    544189716: 1,
    544189821: 1,
    544189827: 1,
    544189837: 1,
    544189853: 1,
    544189891: 1,
    544189911: 1,
    544189939: 1,
    544190002: 1,
    544190031: 1,
    544190067: 1,
    544190083: 1,
    544190098: 1,
    544190117: 1,
    544190157: 1,
    544190190: 1,
    544190200: 1,
    544190216: 1,
    544190249: 1,
    544190280: 1,
    544190295: 1,
    544190307: 1,
    544190332: 1,
    544190365: 1,
    544190413: 1,
    544190422: 1,
    544190442: 1,
    544190467: 1,
    544190500: 1,
    544190520: 1,
    544190537: 1,
    544190623: 1,
    544190635: 1,
    544190686: 1,
    544190704: 1,
    544190722: 1,
    544190742: 1,
    544190771: 1,
    544190794: 1,
    544190800: 1,
    544190856: 1,
    544190915: 1,
    544190989: 1,
    544191064: 1,
    544191121: 1,
    544191173: 1,
    544191243: 1,
    544191300: 1,
    544191349: 1,
    544191417: 1,
    544191464: 1,
    544191544: 1,
    544191584: 1,
    544191619: 1,
    544191667: 1,
    544191737: 1,
    544191792: 1,
    544191840: 1,
    544191887: 1,
    544191933: 1,
    544192009: 1,
    544192216: 1,
    544192287: 1,
    544192318: 1,
    544192356: 1,
    544192396: 1,
    544192476: 1,
    544192534: 1,
    544192563: 1,
    544192606: 1,
    544192690: 1,
    544192744: 1,
    544192784: 1,
    544192828: 1,
    544192869: 1,
    544192934: 1,
    544192990: 1,
    544193021: 1,
    544193062: 1,
    544193121: 1,
    544193176: 1,
    544193214: 1,
    544193264: 1,
    544193309: 1,
    544193369: 1,
    544193417: 1,
    544193483: 1,
    544193507: 1,
    544193595: 1,
    544193618: 1,
    544193652: 1,
    544193913: 1,
    544194027: 1,
    544194063: 1,
    544194115: 1,
    544194155: 1,
    544194219: 1,
    544194265: 1,
    544194312: 1,
    544194352: 1,
    544194394: 1,
    544194470: 1,
    544194525: 1,
    544194594: 1,
    544194621: 1,
    544194681: 1,
    544194741: 1,
    544194775: 1,
    544194838: 1,
    544194876: 1,
    544194943: 1,
    544194995: 1,
    544195032: 1,
    544195058: 1,
    544195123: 1,
    544195181: 1,
    544195205: 1,
    544195242: 1,
    544195304: 1,
    544195365: 1,
    544195405: 1,
    544195494: 1,
    544195548: 1,
    544195589: 1,
    544195655: 1,
    544195684: 1,
    544195740: 1,
    544195801: 1,
    544195828: 1,
    544195864: 1,
    544195921: 1,
    544195975: 1,
    544196024: 1,
    544196064: 1,
    544196108: 1,
    544196166: 1,
    544196235: 1,
    544196269: 1,
    544196312: 1,
    544196375: 1,
    544196415: 1,
    544196464: 1,
    544196497: 1,
    544196550: 1,
    544196606: 1,
    544196654: 1,
    544196704: 1,
    544196746: 1,
    544196841: 1,
    544196859: 1,
    544196912: 1,
    544197087: 1,
    544197117: 1,
    544197160: 1,
    544197253: 1,
    544197315: 1,
    544197366: 1,
    544197454: 1,
    544197495: 1,
    544197544: 1,
    544197595: 1,
    544197635: 1,
    544197700: 1,
    544197746: 1,
    544197791: 1,
    544197831: 1,
    544197888: 1,
    544197946: 1,
    544197988: 1,
    544198036: 1,
    544198079: 1,
    544198148: 1,
    544198194: 1,
    544198250: 1,
    544198289: 1,
    544198368: 1,
    544198418: 1,
    544198458: 1,
    544198521: 1,
    544198705: 1,
    544199012: 1,
    544199073: 1,
    544199121: 1,
    544199166: 1,
    544199206: 1,
    544199264: 1,
    544199321: 1,
    544199362: 1,
    544199401: 1,
    544199455: 1,
    544199528: 1,
    544199588: 1,
    544199628: 1,
    544199661: 1,
    544199728: 1,
    544199791: 1,
    544199851: 1,
    544199880: 1,
    544199930: 1,
    544199983: 1,
    544200033: 1,
    544200064: 1,
    544200095: 1,
    544200158: 1,
    544200222: 1,
    544200251: 1,
    544200290: 1,
    544200336: 1,
    544200397: 1,
    544200429: 1,
    544200525: 1,
    544200577: 1,
    544200649: 1,
    544200698: 1,
    544200740: 1,
    544200794: 1,
    544200840: 1,
    544200875: 1,
    544200921: 1,
    544200981: 1,
    544201046: 1,
    544201081: 1,
    544201127: 1,
    544201174: 1,
    544201245: 1,
    544201296: 1,
    544201320: 1,
    544201358: 1,
    544201419: 1,
    544201454: 1,
    544201501: 1,
    544201547: 1,
    544201602: 1,
    544201665: 1,
    544201698: 1,
    544201736: 1,
    544201792: 1,
    544201857: 1,
    544201895: 1,
    544201958: 1,
    544202110: 1,
    544202148: 1,
    544202185: 1,
    544202242: 1,
    544202292: 1,
    544202326: 1,
    544202376: 1,
    544202404: 1,
    544202463: 1,
    544202516: 1,
    544202547: 1,
    544202587: 1,
    544202637: 1,
    544202708: 1,
    544202734: 1,
    544202777: 1,
    544202827: 1,
    544202893: 1,
    544202930: 1,
    544202976: 1,
    544203013: 1,
    544203070: 1,
    544203128: 1,
    544203167: 1,
    544203203: 1,
    544203244: 1,
    544203316: 1,
    544203362: 1,
    544203398: 1,
    544203441: 1,
    544203658: 1,
    544203715: 1,
    544203814: 1,
    544203836: 1,
    544203891: 1,
    544203949: 1,
    544203999: 1,
    544204039: 1,
    544204076: 1,
    544204131: 1,
    544204252: 1,
    544204279: 1,
    544204325: 1,
    544204400: 1,
    544204434: 1,
    544204464: 1,
    544204518: 1,
    544204588: 1,
    544204619: 1,
    544204663: 1,
    544204711: 1,
    544204762: 1,
    544204815: 1,
    544204860: 1,
    544204897: 1,
    544204956: 1,
    544205015: 1,
    544205063: 1,
    544205099: 1,
    544205136: 1,
    544205251: 1,
    544205315: 1,
    544205363: 1,
    544205428: 1,
    544205487: 1,
    544205519: 1,
    544205555: 1,
    544205633: 1,
    544205687: 1,
    544205729: 1,
    544205767: 1,
    544205823: 1,
    544205879: 1,
    544205936: 1,
    544205972: 1,
    544206019: 1,
    544206084: 1,
    544206115: 1,
    544206158: 1,
    544206215: 1,
    544206265: 1,
    544206319: 1,
    544206370: 1,
    544206400: 1,
    544206440: 1,
    544206512: 1,
    544206558: 1,
    544206591: 1,
    544206635: 1,
    544206720: 1,
    544206917: 1,
    544206961: 1,
    544207057: 1,
    544207116: 1,
    544207181: 1,
    544207213: 1,
    544207257: 1,
    544207310: 1,
    544207374: 1,
    544207421: 1,
    544207462: 1,
    544207504: 1,
    544207556: 1,
    544207606: 1,
    544207642: 1,
    544207681: 1,
    544207733: 1,
    544207795: 1,
    544207827: 1,
    544207870: 1,
    544207909: 1,
    544207955: 1,
    544207996: 1,
    544208029: 1,
    544208052: 1,
    544208109: 1,
    544208175: 1,
    544208207: 1,
    544208245: 1,
    544208287: 1,
    544208445: 1,
    544208484: 1,
    544208538: 1,
    544208589: 1,
    544208613: 1,
    544208661: 1,
    544208723: 1,
    544208768: 1,
    544208804: 1,
    544208841: 1,
    544208896: 1,
    544208950: 1,
    544209027: 1,
    544209067: 1,
    544209103: 1,
    544209161: 1,
    544209207: 1,
    544209259: 1,
    544209297: 1,
    544209333: 1,
    544209390: 1,
    544209447: 1,
    544209488: 1,
    544209521: 1,
    544209584: 1,
    544209641: 1,
    544209690: 1,
    544209723: 1,
    544209773: 1,
    544209846: 1,
    544210051: 1,
    544210134: 1,
    544210264: 1,
    544210303: 1,
    544210360: 1,
    544210429: 1,
    544210492: 1,
    544210521: 1,
    544210566: 1,
    544210637: 1,
    544210705: 1,
    544210749: 1,
    544210818: 1,
    544210844: 1,
    544210932: 1,
    544210965: 1,
    544211020: 1,
    544211087: 1,
    544211149: 1,
    544211208: 1,
    544211237: 1,
    544211266: 1,
    544211330: 1,
    544211377: 1,
    544211423: 1,
    544211492: 1,
    544211523: 1,
    544211611: 1,
    544211655: 1,
    544211676: 1,
    544211740: 1,
    544211799: 1,
    544211816: 1,
    544211888: 1,
    544211948: 1,
    544212006: 1,
    544212046: 1,
    544212084: 1,
    544212124: 1,
    544212196: 1,
    544212241: 1,
    544212274: 1,
    544212322: 1,
    544212385: 1,
    544212434: 1,
    544212498: 1,
    544212543: 1,
    544212620: 1,
    544212639: 1,
    544212718: 1,
    544212761: 1,
    544212838: 1,
    544212871: 1,
    544212889: 1,
    544212962: 1,
    544213023: 1,
    544213066: 1,
    544213101: 1,
    544213155: 1,
    544213188: 1,
    544213265: 1,
    544213282: 1,
    544213357: 1,
    544213484: 1,
    544213520: 1,
    544213572: 1,
    544213634: 1,
    544213696: 1,
    544213723: 1,
    544213776: 1,
    544213860: 1,
    544213930: 1,
    544213949: 1,
    544214015: 1,
    544214111: 1,
    544214137: 1,
    544214180: 1,
    544214220: 1,
    544214293: 1,
    544214350: 1,
    544214370: 1,
    544214424: 1,
    544214516: 1,
    544214548: 1,
    544214623: 1,
    544214661: 1,
    544214719: 1,
    544214770: 1,
    544214789: 1,
    544214849: 1,
    544214914: 1,
    544214945: 1,
    544214986: 1,
    544215076: 1,
    544215128: 1,
    544215146: 1,
    544215204: 1,
    544215281: 1,
    544215317: 1,
    544215380: 1,
    544215423: 1,
    544215474: 1,
    544215507: 1,
    544215593: 1,
    544215631: 1,
    544215668: 1,
    544215742: 1,
    544215799: 1,
    544215835: 1,
    544215899: 1,
    544215978: 1,
    544216000: 1,
    544216048: 1,
    544216101: 1,
    544216155: 1,
    544216208: 1,
    544216267: 1,
    544216337: 1,
    544216436: 1,
    544216481: 1,
    544216516: 1,
    544216582: 1,
    544216647: 1,
    544216740: 1,
    544216802: 1,
    544216850: 1,
    544216895: 3,
  },
  moment: {
    amount: 1,
    unit: "w",
  },
  token: 704036829,
};
export default settings;
