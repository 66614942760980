import "./App.css";
import { Route, Routes, useParams } from "react-router-dom";
import { withAppBar } from "./hoc/withAppBar";
import Home from "./Home";
import Dice from "./Dice";
import Roll from "./Roll";
import Relay from "./Relay";
import Assets from "./Assets";
import {
  Badge,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { Image } from "react-bootstrap";
import config from "./config/skuli_frens";
import config2 from "./config/algobulls";
import config3 from "./config/anml";
import Ticker from "react-ticker";
import { textAlign } from "@mui/system";
import { shuffle } from "./functions";

function App() {
  const params = useParams();
  const assets = shuffle([
    ...Object.entries(config.assets).map((el) => el),
    //Object.entries(config2.assets).map(el => el),
    //Object.entries(config3.assets).map(el => el),
  ]);
  return (
    <div>
      <Ticker speed={15}>
        {({ index }) => (
          <>
            <h1 style={{color: 'red'}}>
              {assets.reduce((acc, val) => acc + val[1], 0)} $SKFRT per week
              {`　　　　　　　　　　　　　　　　　　　　`}
            </h1>
            {false && <h1>
              {`　　　　　　　　　　　`}10000 $ANML per week
            </h1>}
            {false && <h1 style={{color: 'white'}}>
              {`　　　　`}1000 $BULLion per week
            </h1>}
          </>
        )}
      </Ticker>
      <div style={{
        display: 'flex',
        columnGap: '10px',
        justifyContent: 'center'
      }}>
      <Button variant="outlined">skuli frens</Button>
      <Button disabled variant="outlined">anml</Button>
      <Button disabled variant="outlined">algobulls</Button>
      </div>
      <ImageList className="mt-3" variant="masonry" cols={3} gap={8}>
        {assets.map(([index, reward]) => (
          <ImageListItem>
            <img
              src={`${`/assets/${index}.png`}?w=248&fit=crop&auto=format`}
              alt={"asdf"}
              loading="lazy"
            />
            <ImageListItemBar
              style={{ textAlign: "center", color: "white" }}
              position="below"
              title={`${index} (${reward} $SKFRT per week)`}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

export default withAppBar(App);
